<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-row class="py-1 px-2" align="center" justify="space-between">

    <v-col cols="10">
      <v-row align="center" justify="space-between">
        <!-- list of operands (optional) -->
        <v-col class="pa-0 pr-3" cols="5" v-if="typeof currentRule.operands !== 'undefined'">
          <v-autocomplete v-model="currentRule.operand" :items="currentRule.operands" return-object outlined single-line dense hide-details />
        </v-col>

        <!-- list of operators (e.g. =, !=, >, <) -->
        <v-col class="pa-0 pr-3" cols="5" v-if="typeof currentRule.operators !== 'undefined' && currentRule.operators.length > 1">
          <v-autocomplete v-model="currentRule.operator" :items="currentRule.operators" return-object outlined single-line dense hide-details />
        </v-col>
        <template v-if="!currentRule.isCombined">
          <!-- range -->
          <v-col cols="10" class="pt-5" v-if="currentRule.type === 'numeric' && currentRule.operator === 'rango'">
            <v-row>
              <v-col class="pa-0">
                <v-text-field v-model.number="currentRule.value" prefix="desde" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" outlined required single-line dense hide-details />
              </v-col>
              <v-col class="pa-0 pl-3">
                <v-text-field v-model.number="currentRule.value2" prefix="hasta" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" outlined required single-line dense hide-details @input="validate" :error="error.active" />
              </v-col>
            </v-row>
            <span class="d-block red--text caption ml-n2 mt-4 mb-n4" v-if="error.msg.length">{{error.msg}}</span>
          </v-col>
          <!-- range -->
          <!-- range date -->
          <v-row v-else-if="currentRule.type === 'date' && currentRule.operator === 'rango'" class="pl-3 pa-0 py-5">
            <v-col cols="6" class="pa-0">
              <v-menu v-model="startDateMenu" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280" z-index="1000">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="currentRule.value" v-bind="attrs" v-on="on" prefix="desde" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                </template>
                <v-date-picker v-model="currentRule.value" @input="startDateMenu=false" no-title :first-day-of-week="1" type="date" locale="es" width="270" color="primary">
                  <v-row class="pt-0 mb-1 px-5">
                    <v-btn class="body-1" :ripple="false" text color="primary" @click="() => { currentRule.value=null; startDateMenu=false }">Limpiar</v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pa-0 pl-3">
              <v-menu v-model="endDateMenu" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280" z-index="1000">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="currentRule.value2" v-bind="attrs" v-on="on" prefix="hasta" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                </template>
                <v-date-picker v-model="currentRule.value2" @input="endDateMenu=false" :min="currentRule.value" no-title :first-day-of-week="1" type="date" locale="es" width="270" color="primary">
                  <v-row class="mb-1 px-5">
                    <v-btn class="body-1" :ripple="false" text color="primary"  @click="() => { currentRule.value2=null; endDateMenu=false }">Limpiar</v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- end range date -->

          <!-- query -->
          <v-col class="pa-0">
            <!-- basic text input -->
            <v-col cols="8" class="pa-0">
              <v-text-field v-if="currentRule.type === 'text'" v-model.trim="currentRule.value" :disabled="currentRule.operator === 'no tiene'" outlined required single-line dense hide-details />
            </v-col>
            <!-- basic number input -->
            <v-col cols="8" class="pa-0">
              <v-text-field v-if="currentRule.id === 'prefix'" v-model.trim="currentRule.value" oninput="this.value=this.value.replace(/[^a-zA-Z0-9]/g,'');" outlined required single-line dense hide-details />
              <template v-else-if="currentRule.type === 'numeric' && currentRule.operator !== 'rango'">
                <v-text-field v-if="currentRule.label === 'Serie'" v-model="currentRule.value" outlined required single-line dense hide-details />
                <v-text-field v-else v-model="currentRule.value" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" outlined required single-line dense hide-details />
              </template>
            </v-col>
            <!-- basic date input -->
            <v-col cols="8" class="pa-0" v-if="currentRule.type === 'date' && currentRule.operator !== 'rango'">
              <v-menu v-model="startDateMenu" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280" z-index="1000">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="currentRule.value" v-bind="attrs" v-on="on" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                </template>
                <v-date-picker v-model="currentRule.value" @input="startDateMenu=false" no-title :first-day-of-week="1" type="date" locale="es" width="270" color="primary">
                  <v-row class="pt-0 mb-1 px-5">
                    <v-btn class="body-1" :ripple="false" text color="primary" @click="() => { currentRule.value=null; startDateMenu=false }">Limpiar</v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
            <!-- basic date input -->

            <!-- radio input -->
            <v-radio-group class="mt-n1 py-0" v-if="currentRule.type === 'radio'" v-model="currentRule.value" row hide-details>
              <v-radio v-for="choice in currentRule.choices" :key="choice.value" :label="choice.label" :value="choice.value" dense :ripple="false" />
            </v-radio-group>

            <!-- Select without groups -->
            <v-col cols="8" class="pa-0" v-if="currentRule.type === 'select'">
              <v-autocomplete v-model="select" :class="{'isMultiple' : currentRule.isMultiple}" :multiple="currentRule.isMultiple" :items="currentRule.choices" @change="currentRule.value = select" no-data-text="Sin información" item-text="label" item-value="value" outlined single-line dense hide-details />
            </v-col>
          </v-col>
        </template>
        <template v-if="currentRule.dependent && currentRule.id === 'branch_id'">
          <v-col cols="10" class="pt-5">
            <v-row>
              <v-col class="pa-0">
                <v-text-field v-model="currentRule.value" prefix="Tienda" outlined required single-line dense hide-details />
              </v-col>
              <v-col class="pa-0 pl-3">
                <v-text-field v-model="currentRule.value2" prefix="Pos" :disabled="currentRule.id === 'branch_id' && !currentRule.value?.length" outlined required single-line dense hide-details />
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else-if="currentRule.dependent && currentRule.id === 'date'">
          <v-col cols="10" class="pt-5">
            <v-row>
              <v-col cols="4" class="pa-0">
                <v-menu v-model="startDateMenu" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280" z-index="1000">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="currentRule.value" prefix="Fecha" v-bind="attrs" v-on="on" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                </template>
                <v-date-picker v-model="currentRule.value" @input="startDateMenu=false" no-title :first-day-of-week="1" type="date" locale="es" width="270" color="primary">
                  <v-row class="pt-0 mb-1 px-5">
                    <v-btn class="body-1" :ripple="false" text color="primary" @click="() => { currentRule.value=null; startDateMenu=false }">Limpiar</v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
              </v-col>
              <v-col class="pa-0 pl-3">
                <v-text-field v-model="currentRule.value2" prefix="Nombre de archivo" :disabled="currentRule.id === 'date' && !currentRule.value?.length" outlined required single-line dense hide-details />
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import debounce from 'lodash/debounce'
export default {
  props: {
    rule: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    startDateMenu: false,
    endDateMenu: false,
    select: null,
    error: {
      msg: '',
      active: false
    }
  }),
  watch: {
    currentRule: {
      handler (val) {
        if (val.isCombined && ['DocumentsList', 'DocumentsBatchListCreate'].includes(this.$route.name)) {
          val.value2 = ['branch_id', 'date'].includes(val.id) && val.value.length === 0 ? null : val.value2
        }
      },
      inmediate: true,
      deep: true
    },
    'currentRule.operator': {
      handler (newVal, oldVal) {
        this.currentRule.value = newVal === 'no tiene' ? 'SN' : null
        if (newVal === 'rango' || (oldVal === 'rango' && ['es igual', 'es menor a', 'es mayor a'].includes(newVal))) {
          this.currentRule.value = null
          this.$emit('update:rule', this.currentRule)
        }
      },
      inmediate: true,
      deep: true
    }
  },
  computed: {
    currentRule: {
      get () {
        let cr = this.rule
        if (cr.valueType === 'number') {
          cr.value = parseInt(cr.value)
        }
        return cr
      },
      set (val) {
        this.$emit('update:rule', val)
      }
    }
  },
  mounted () {
    if (this.currentRule.type === 'select' && this.$route.query[this.currentRule.id]) {
      if (this.currentRule.isMultiple) {
        this.select = this.$route.query[this.currentRule.id]
      } else {
        this.select = this.currentRule.valueType === 'number' ? parseInt(this.$route.query[this.currentRule.id]) : this.$route.query[this.currentRule.id]?.split(',')
      }
    }
  },
  methods: {
    validate: debounce(function (e) {
      this.error = parseInt(e) < this.currentRule.value ? { active: true, msg: 'desde no puede ser menor que hasta'} : { active: false, msg: ''}
    }, 700),
    getSelect (item = {}) {
      if (!this.currentRule.value?.find((e) => e.id === item.id)) this.currentRule.value.push(item)
      else {
        const index = this.currentRule.value.findIndex(i => i.id === item.id)
        this.currentRule.value.splice(this.currentRule.value?.indexOf(index), 1)
        this.currentRule.value = [...this.currentRule.value]
      }
    }
  }
}
</script>